<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('common.filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group :label="$t('common.start_at')" label-for="state-filter">
            <b-overlay :show="itemsLoading">
              <b-form-datepicker id="start-at" v-model="startAt" :max="endAt" :placeholder="$t('common.start_at')" :locale="$i18n.locale" size="sm" />
            </b-overlay>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group :label="$t('common.end_at')" label-for="state-filter">
            <b-overlay :show="itemsLoading">
              <b-form-datepicker id="end-at" v-model="endAt" :min="startAt" :placeholder="$t('common.end_at')" :locale="$i18n.locale" size="sm" />
            </b-overlay>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <!-- state -->
          <b-form-group :label="$t('marketplace_accounting.filter.status')" label-for="state-filter">
            <b-overlay :show="itemStateLoading || itemsLoading">
              <v-select
                id="state-filter"
                v-model="invoiceState"
                :options="InvoiceState"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="item => item.value"
                :placeholder="$t('marketplace_accounting.filter.status')"
                class="select-size-sm"
                label="value"
              />
            </b-overlay>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <!-- type -->
          <b-form-group :label="$t('common.type')" label-for="type-filter">
            <b-overlay :show="filtersOptionsLoading || itemsLoading">
              <v-select
                id="type-filter"
                v-model="invoiceType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="InvoiceType"
                :placeholder="$t('marketplace_accounting.filter.invoice_type')"
                :reduce="item => item.value"
                class="select-size-sm"
                label="value"
              />
            </b-overlay>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex align-items-center justify-content-end">
          <b-button
            id="action-reset-filter"
            :disabled="filtersOptionsLoading || itemsLoading || itemStateLoading"
            size="sm"
            variant="outline-secondary"
            class="mr-1"
            @click="clearFilters()"
          >
            {{ $t('action.clear') }}
          </b-button>
          <b-button id="action-filter" :disabled="filtersOptionsLoading || itemsLoading || itemStateLoading" size="sm" @click="fetchItems(true)">
            {{ $t('action.filter') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'AccountingFilters',

  computed: {
    ...mapState('accounting', ['filtersOptions', 'filtersOptionsLoading', 'itemsLoading', 'itemStateLoading', 'itemState']),
    ...mapState('appConfig', ['lang']),
    ...mapState('enum', ['InvoiceState', 'PaymentMethodsType', 'InvoiceType']),
    ...mapFields('accounting', ['filter.paymentMethod', 'filter.invoiceType', 'filter.invoiceState', 'filter.statesCodes', 'filter.startAt', 'filter.endAt']),
  },

  methods: {
    ...mapActions('enum', ['fetchEnum2']),
    ...mapActions('accounting', ['fetchItems', 'fetchItemFiltersOption', 'clearFilters', 'fetchItemState']),
  },

  mounted() {
    this.fetchItemFiltersOption()
    this.fetchItemState()
    this.fetchEnum2('InvoiceState')
    this.fetchEnum2('InvoiceType')
  },
}
</script>

<style lang="scss">
.vs__search,
.vs__search:focus {
  line-height: 2.1 !important;
}
</style>
