<template>
  <div id="items">
    <!-- Filters -->
    <AccountingFilters v-show="showFilters" />
    <!-- Table Container Card -->
    <b-card class="mb-0" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col class="d-flex align-items-center justify-content-start mb-1 mb-lg-0" cols="12" lg="1">
            <b-form-select
              v-model="numberOfItemsPerPage"
              v-b-tooltip.hover.top="$t('action.per_page')"
              :disabled="itemsLoading"
              :options="dataTable.parPageOption"
              class="per-page-selector"
              @change="fetchItems(true)"
            />
          </b-col>
          <!-- search and filter-->
          <b-col class="d-flex align-items-center justify-content-start mb-1 mb-lg-0" cols="12" lg="7">
            <b-input-group class="mr-1">
              <!--              <b-input-group-prepend>-->
              <!--                <b-form-select v-model="searchField" :disabled="itemsLoading" class="select-search" @change="() => (search = '')">-->
              <!--                  <b-form-select-option v-for="(option, index) in searchFieldOptions" :key="index" :value="option">-->
              <!--                    &lt;!&ndash; <span v-if="option === 'all'"> All</span> &ndash;&gt;-->
              <!--                    <span v-if="option === 'state'"> {{ $t('order.status') }}</span>-->
              <!--                    <span v-if="option === 'reference'"> {{ $t('order.order_number') }}</span>-->
              <!--                  </b-form-select-option>-->
              <!--                </b-form-select>-->
              <!--              </b-input-group-prepend>-->
              <b-form-input
                v-model="searchQuery"
                :disabled="itemsLoading"
                :placeholder="$t('action.search')"
                @keydown.enter="fetchItems(true)"
                @keyup.backspace="() => searchQuery === '' && fetchItems(true)"
              />
              <b-input-group-append>
                <b-button id="quote-search" :disabled="searchQuery === '' || itemsLoading" size="sm" @click="fetchItems(true)">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              id="quote-filter"
              v-b-tooltip.hover.top="$t('action.show_filter')"
              :disabled="itemsLoading"
              class="text-nowrap"
              variant="outline-secondary"
              @click="() => (showFilters = !showFilters)"
            >
              <feather-icon icon="FilterIcon" />
            </b-button>
          </b-col>
          <!-- action -->
          <!--          <b-col cols="12" lg="4" class="d-flex align-items-center justify-content-end">-->
          <!--            <div class="d-flex align-items-center justify-content-end">-->
          <!--              <b-button id="quote-export" variant="outline-primary" :disabled="itemsLoading">-->
          <!--                <span class="text-nowrap d-none d-lg-block">-->
          <!--                  <feather-icon icon="UploadIcon" class="mr-50" />-->
          <!--                  {{ $t('action.export') }}-->
          <!--                </span>-->
          <!--                <feather-icon icon="UploadIcon" class="d-lg-none" />-->
          <!--              </b-button>-->
          <!--            </div>-->
          <!--          </b-col>-->
        </b-row>
      </div>
      <!-- TABLE -->
      <b-table
        ref="accounting-table"
        :busy="itemsLoading"
        :empty-text="$t('common.no_record_found')"
        :fields="itemsFields"
        :items="items"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        class="position-relative table-dropdown-action"
        hover
        no-local-sorting
        no-sort-reset
        primary-key="id"
        responsive
        show-empty
        @sort-changed="updateSort"
      >
        <!-- LOADING -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <!-- HEAD -->
        <template #head(number)>
          {{ $t('accounting.number') }}
        </template>
        <template #head(dueDate)>
          {{ $t('common.date') }}
        </template>
        <template #head(type)>
          {{ $t('accounting.type') }}
        </template>
        <template #head(client)>
          {{ $t('accounting.client') }}
        </template>
        <template #head(category)>
          {{ $t('accounting.category') }}
        </template>
        <template #head(country)>
          {{ $t('accounting.country') }}
        </template>
        <template #head(beforeTax)>
          {{ $t('accounting.before_tax') }}
        </template>
        <template #head(totalVat)>
          {{ $t('accounting.total_vat') }}
        </template>
        <template #head(status)>
          {{ $t('accounting.status') }}
        </template>
        <!-- CELL -->
        <!-- number -->
        <template #cell(number)="{ item }">
          <span class="text-nowrap"> {{ item.id }} </span>
        </template>
        <!-- dueDate -->
        <template #cell(dueDate)="{ item }">
          <span class="text-nowrap">{{ item.date | dateFormatUtc }}</span>
        </template>
        <!-- type -->
        <template #cell(type)="{ item }">
          <span class="text-nowrap"> {{ item.invoiceType.charAt(0).toUpperCase() + item.invoiceType.slice(1) }} </span>
        </template>
        <!-- client -->
        <template #cell(client)="{ item }">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar />
            </template>
            <span class="font-weight-bold d-block text-nowrap client">
              {{ item.client.legalName }}
            </span>
          </b-media>
        </template>
        <!-- category -->
        <template #cell(category)="{ item }">
          <span class="text-nowrap"> {{ item.client.mainTypology }} </span>
        </template>
        <!-- country -->
        <template #cell(country)="{ item }">
          <span class="text-nowrap"> {{ item.client.country }} </span>
        </template>
        <!-- beforeTax -->
        <template #cell(beforeTax)="{ item }">
          <span class="text-nowrap"> {{ item.priceExcludingTaxes | priceFormat }} </span>
        </template>
        <!-- totalVat -->
        <template #cell(totalVat)="{ item }">
          <span class="text-nowrap">{{ item.priceIncludingTaxes | priceFormat }} </span>
        </template>
        <!-- status -->
        <template #cell(status)="{ item }">
          <b-badge v-if="item.state" :variant="item.state === 'paid' ? 'success' : 'warning'" pill>
            {{ item.state.charAt(0).toUpperCase() + item.state.slice(1) }}
          </b-badge>
        </template>
        <!-- action -->
        <template #cell(actions)="{ item }">
          <div id="accounting-table-data-table-actions">
            <div class="text-nowrap">
              <b-dropdown v-if="item.quotes.length > 0 || item.paymentUrl" id="accounting-table-data-table-actions-dropdown" no-caret variant="link">
                <template #button-content>
                  <feather-icon class="align-middle text-body" icon="MoreVerticalIcon" size="16" />
                </template>
                <!--show-->
                <b-dropdown-item v-if="item.quotes.length > 0" :disabled="!$can('OPERATOR_ACCOUNTING_VIEW')" :to="{ name: 'orderAccountingShow', params: { id: item.quotes[0].id } }">
                  <feather-icon id="accounting-table-data-table-dropdown-action-show-invoice" icon="EyeIcon" size="16" />
                  <span class="align-middle ml-50">{{ $t('action.show') }}</span>
                </b-dropdown-item>
                <!--show stripe-->
                <b-dropdown-item v-if="item.paymentUrl" :disabled="!$can('OPERATOR_ACCOUNTING_VIEW')" :href="item.paymentUrl" target="_blank">
                  <feather-icon id="accounting-table-data-table-dropdown-action-show-stripe" icon="AlignJustifyIcon" size="16" />
                  <span class="align-middle ml-50">{{ $t('action.show_in_stripe') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </template>
      </b-table>
      <!--PAGINATION-->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Items -->
          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
            <app-data-table-entries-display :items-length="items.length" :page="page" :per-page="numberOfItemsPerPage" :total-count="pagination.totalItems" />
          </b-col>
          <!-- Pagination -->
          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">
            <b-pagination
              v-model="page"
              :per-page="numberOfItemsPerPage"
              :total-rows="pagination.totalItems"
              align="end"
              class="mb-0 mt-1 mt-sm-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @change="changePage($event)"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import UIHelper from '@/helpers/ui'

import AppDataTableEntriesDisplay from '@/components/AppDataTableEntriesDisplay.vue'
import AccountingFilters from './AccountingFilters.vue'

export default {
  name: 'AccountingList',

  components: {
    AppDataTableEntriesDisplay,
    AccountingFilters,
  },
  mixins: [UIHelper],

  data() {
    return {
      showFilters: false,
      itemsFields: [
        { key: 'number', sortable: true },
        { key: 'dueDate', sortable: true },
        { key: 'type', sortable: true },
        { key: 'client', sortable: true },
        { key: 'category', sortable: true },
        { key: 'country', sortable: true },
        { key: 'beforeTax', sortable: true },
        { key: 'totalVat', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions', thClass: 'text-right', tdClass: 'text-right' },
      ],
    }
  },

  computed: {
    ...mapState('accounting', ['items', 'pagination', 'searchQuery', 'itemsLoading']),
    ...mapState('appConfig', ['dataTable', 'lang']),
    ...mapGetters('accounting', ['searchFieldOptions']),

    ...mapFields('accounting', ['paginate.page', 'paginate.numberOfItemsPerPage', 'dataTableSort.sortBy', 'dataTableSort.sortDesc', 'searchField', 'searchQuery']),
    search: {
      get() {
        return this.searchQuery
      },
      set(value) {
        this.setSearch(value)
      },
    },
  },

  mounted() {
    this.fetchItems()
  },

  methods: {
    ...mapActions('accounting', ['fetchItems', 'setSearch', 'setSort', 'setPage']),

    updateSort(e) {
      this.sortBy = e.sortBy
      this.sortDesc = e.sortDesc
      this.setSort({ sortBy: e.sortBy, sortDesc: e.sortDesc })
    },
    changePage(number) {
      this.setPage(number)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 65px;
}
.select-search {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}
.client {
  margin-top: 0.5rem;
}
</style>
